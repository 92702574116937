import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";
import App from "./App";
import Dynamsoft from "dwt";
import { dynamsoft, CI_COMMIT_SHORT_SHA } from "config/settings";
import { excludeGraphQLFetch } from "apollo-link-sentry";

Dynamsoft.DWT.ProductKey = `${dynamsoft.productKey}`;
Dynamsoft.DWT.ResourcesPath = `/${dynamsoft.resourcePath}`;
Dynamsoft.DWT.Containers = [{ ContainerId: "dwtcontrolContainer", Width: 270, Height: 350 }];

if (process.env.REACT_APP_ENV !== "local") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "TypeError: NetworkError when attempting to fetch resource.",
      "Failed to fetch",
      "Invalid Token",
      "cancelled",
      "Load failed",
    ],
    release: CI_COMMIT_SHORT_SHA,

    beforeBreadcrumb: excludeGraphQLFetch,
  });
  Sentry.setTag("CI_COMMIT_SHORT_SHA", CI_COMMIT_SHORT_SHA);
}
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
