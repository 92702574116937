import { Col, Row, Spin } from "antd";

export default function LoadingPage(): JSX.Element {
  return (
    <div className="loading-page">
      <Row>
        <Col span={12} offset="3">
          <div className="text-center">
            <Spin size="large" />
          </div>
        </Col>
      </Row>
    </div>
  );
}
