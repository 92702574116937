import { NoAccess } from "@newrai/ant-design";
import Settings from "config/settings";
import { lazy, Suspense } from "react";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import LoadingPage from "views/Base/components/LoadingPage";
import Page404 from "views/Base/components/Page404";
import PrivateRoute from "./PrivateRoute";

const Home = lazy(() => import("views/Home"));
const Documents = lazy(() => import("views/Documents"));

const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<PrivateRoute />}>
        <Route index element={<Home />} />
        <Route path="company/:companyID" element={<Documents />}>
          <Route path="book/:bookID" element={<Documents />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Page404 />
          </Suspense>
        }
      />
      <Route path="no-access" element={<NoAccess dashboardUrl={Settings.dashboard} />} />
    </>,
  ),
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

export { routes };
