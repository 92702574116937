import { useLazyQuery } from "@apollo/client";
import { getHashParams, useOnErrorGraphql } from "@newrai/ant-design";
import i18n from "config/i18n/i18n";
import Settings from "config/settings";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { IS_AUTH_QUERY } from "queries/general";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Base from "views/Base";
import useStore from "../store";
import * as Sentry from "@sentry/browser";

const ACCESS_PERMISSION = "neoscan-pro.can-access";

export default function PrivateRoute() {
  const { onErrorGraphql } = useOnErrorGraphql();
  const navigate = useNavigate();
  const location = useLocation();

  const relativeUrlPath = useRef("");
  const relativePathName = `${location.pathname}${location.search}`;

  const { userData, setUserData } = useStore();

  const [getUserData] = useLazyQuery<IsAuthQueryResponseType>(IS_AUTH_QUERY, {
    onError: onErrorGraphql,
    onCompleted: ({ getUserByToken }) => {
      if (getUserByToken) {
        Sentry.setUser({
          email: getUserByToken.email,
          id: getUserByToken.id,
        });
        const canAccess = getUserByToken.permissions
          ? getUserByToken.permissions.findIndex(permission => permission === ACCESS_PERMISSION) >
            -1
          : false;

        if (!canAccess) {
          navigate("/no-access");
        } else {
          setUserData(getUserByToken);
          i18n.changeLanguage(getUserByToken.language.toLowerCase());
          navigate(relativeUrlPath.current, { replace: true });
        }
      } else {
        window.location.href = Settings.fullLoginUrl;
      }
    },
  });

  const handleLoginUseEffect = async () => {
    relativeUrlPath.current = relativePathName;

    if (location.hash) {
      const expireSeconds = getHashParams(location, "expires_in");
      const expireDate = dayjs().add(Number(expireSeconds) * 1000, "ms");
      const accessTokenValue = getHashParams(location, "access_token");

      Cookies.set("access_token", accessTokenValue, {
        path: "/",
        expires: expireDate.toDate(),
      });
      Cookies.set("expireDate", expireDate.toISOString(), {
        path: "/",
        expires: expireDate.toDate(),
      });
    }

    const cookieAccessToken = Cookies.get("access_token");
    if (cookieAccessToken && !userData) {
      await getUserData();
    } else {
      window.location.href = Settings.fullLoginUrl;
      return;
    }
  };

  useEffect(() => {
    handleLoginUseEffect();
  }, []);

  return <>{userData && <Base />}</>;
}
